import React from 'react';
import { StyledIndexScreen } from '../styles/basic-styles';

const IndexPage = () => {
  return(
    <StyledIndexScreen>
      <div>
        Hello! Welcome to my page!
      </div>
    </StyledIndexScreen>
  );
};

export default IndexPage;